import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import algorandtimesquarevideo from "../assets/images/banner.mp4";
import algorandImage from "../assets/images/algorandImage.jpeg";
import "../assets/styles/style.css";
import { GitHub } from "@mui/icons-material";

import { Grid, Typography, Box } from "@mui/material";

const styles = {
  videoDiv: `
    height:720px;
    width:100vw;
    position: relative;
    overflow:hidden;
    filter: opacity(0.45);
    background-image: url(${algorandImage});
    `,
  video: `
    width: 100vw;
    filter: opacity(0.75) hue-rotate(-103deg) !important;
    height:100%;
    position: absolute;
    object-fit: cover;
    z-index: 2;
    transform: translateX(-50%) translateY(0%);
      `,
  videoCaption: `
    z-index: 3;
    position: relative;
    text-align: left;
    color: #ffffff;
    padding-top: 3%;
    padding-left: 120px;
    `,
  text: `
    padding-left: 30px`,
  button: `
  margin-top:30px; 
       `,
};

function Banner(props) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box
        css={css`
          ${styles.videoDiv}
        `}
        sx={{
          height: {
            xl: "720px",
            lg: "700px",
            md: "630px",
            sm: "550px",
            xs: "430px",
          },
        }}
      >
        <video
          autoPlay
          muted
          loop
          css={css`
            ${styles.video}
          `}
        >
          <source src={algorandtimesquarevideo} type="video/mp4" />
        </video>

        <Box
          css={css`
            ${styles.videoCaption}
          `}
          sx={{
            paddingLeft: {
              xl: "135px",
              lg: "130px",
              md: "120px",
              sm: "100px",
              xs: "10px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Titillium Web",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            DIDs &{" "}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Titillium Web",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            Verifiable Credentials{" "}
          </Typography>



          <Typography
            sx={{
              fontFamily: "Titillium Web",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            Protocol
          </Typography>
          <Typography
            sx={{
              fontFamily: "Titillium Web",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            on Algorand
          </Typography>

          <Box sx={{ display: { md: "flex" } }}>
         
            <Box
              component="a"
              className="animated-button-mainnet"
              href="https://goplausible.xyz"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                borderRadius: "100px",

              }}

            >
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>

              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> MAINNET DAPP </Box>

              </Typography>
            </Box>
            {/* <Box
              component="a"
              className="animated-button-testnet"
              href="https://testnet.goplausible.xyz"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
                borderRadius: "100px"
              }}
            >
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> TESTNET </Box>

              </Typography>
            </Box> */}
            <Box
              component="a"
              className="animated-button-mainnet"
              href="https://thisdid.com"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
                borderRadius: "100px"
              }}
            >
            
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                 
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> DID Resolver </Box>

              </Typography>
            </Box>
            <Box
              component="a"
              className="animated-button-mainnet"
              href="https://github.com/GoPlausible/algorand-mcp"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
                borderRadius: "100px"
              }}
            >
            
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                 
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> Algorand MCP </Box>

              </Typography>
            </Box>
            <Box
              component="a"
              className="animated-button-mainnet"
              href="https://chatgpt.com/g/g-izA6hnC93-algorand-gpt"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
                borderRadius: "100px"
              }}
            >
            
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                 
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> Algorand-GPT </Box>

              </Typography>
            </Box>
            <Box
              component="a"
              className="animated-button-mainnet"
              href="https://chatgpt.com/g/g-rOCQculZQ-did-gpt"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
                borderRadius: "100px"
              }}
            >
            
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Titillium Web",
                }}
              >
                 
                <Box component="text" sx={{ color: '#5d5d5d', fontWeight: "bold" }}> DID-GPT </Box>

              </Typography>
            </Box>
          </Box>


        </Box>
      </Box>
    </Grid>
  );
}

Banner.propTypes = {
  featuresRef: PropTypes.func,
};
export default Banner;
